import storyblokEditable from "~lib/storyblokEditable";

import { Button, Container, Grid, Slider, BlogCard } from "~components";
import { useGetHref } from "~lib";
import { RichTitle } from "~components/common/RichTitle";
import { InviewLoader } from "~components/common/InviewLoader";
import styles from "./BlogPosts.module.scss";

export const BlogPosts = ({ content }) => {
  const {
    button: [button],
    title,
    pages,
  } = content;
  const { getHref } = useGetHref();
  const href = getHref(button?.link);

  return (
    <Container {...storyblokEditable(content)} className={styles.blogPosts}>
      <Grid>
        <h2 className={styles.heading}>
          <RichTitle title={title} />
        </h2>
        {href && button?.text && (
          <Button
            href={href}
            color="primary"
            as="link"
            className={styles.button}
          >
            {button?.text}
          </Button>
        )}
        <div className={styles.sliderWrapper}>
          <Slider spaceBetween={20} hideScrollBar={true}>
            {pages?.map?.((blog) => {
              return (
                <InviewLoader key={blog.id}>
                  <BlogCard
                    blog={blog.content}
                    publishedAt={blog.published_at}
                    to={getHref(blog.full_slug)}
                    featured={false}
                    category={blog.content?.category}
                    detailed={false}
                  />
                </InviewLoader>
              );
            })}
          </Slider>
        </div>
      </Grid>
    </Container>
  );
};
