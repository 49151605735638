import { Box, Stack } from "@storyofams/react-ui";
import storyblokEditable from "~lib/storyblokEditable";

import { Accordion, Text, RichText } from "~components";

export const Faq = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div {...storyblokEditable(content)}>
      <Accordion title={content?.title}>
        <Stack flexDirection="column" space={[3, 5]} pb={2}>
          {content?.questions?.map((question) => (
            <div {...storyblokEditable(question)} key={question?._uid}>
              <Box>
                <Text fontWeight="bold" variant="medium" mb={2} color="grey900">
                  {question?.question}
                </Text>
                <RichText
                  text={question?.answer}
                  fontSize={["12px", "14px"]}
                  fontWeight="regular"
                  lineHeight="high"
                  color="grey900"
                />
              </Box>
            </div>
          ))}
        </Stack>
      </Accordion>
    </div>
  );
};
