import { Stack, SystemProps } from "@storyofams/react-ui";
import storyblokEditable from "~lib/storyblokEditable";

import { LegacyContainer, RichText as RichTextComponent } from "~components";

type RichTextProps = {
  first?: boolean;
  content: {
    text?: any;
  };
} & SystemProps;

export const RichText = ({ first, content, ...props }: RichTextProps) => {
  const { text } = content;

  return (
    <div {...storyblokEditable(content as any)}>
      <LegacyContainer
        pt={first ? 0 : [2, 5]}
        pb={[2, 5]}
        maxWidth={["100%", "720px", "960px"]}
        {...props}
      >
        <Stack space={[2, 3]} flexDirection="column">
          {!!text && <RichTextComponent text={text} />}
        </Stack>
      </LegacyContainer>
    </div>
  );
};
