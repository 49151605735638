import { Box, SystemProps, Icon } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import { useRouter } from "next/router";
import storyblokEditable from "~lib/storyblokEditable";

import {
  Breadcrumb,
  BreadcrumbItem,
  LegacyContainer,
  Heading,
} from "~components";
import { Home } from "~components/common/Icons";

type HeaderProps = {
  first?: boolean;
  content: {
    title?: string;
    image?: any;
  };
  breadcrumb?: boolean;
} & SystemProps;

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const getBreadcrumbItems = (query) => {
  const items: BreadcrumbItem[] = [
    {
      name: "Home",
      content: <Icon icon={Home} fontSize={2} />,
      link: "/blog",
    },
  ];
  if (query?.category) {
    items.push({
      name: capitalizeFirstLetter(
        (query.category as string).split("-").join(" ")
      ),
      link: `/blog/${query.category}`,
    });
  }
  if (query?.slug) {
    items.push({
      name: capitalizeFirstLetter((query.slug as string).split("-").join(" ")),
      link: `/${query.slug}`,
    });
  }
  return items;
};

export const Header = ({
  first,
  content,
  breadcrumb,
  ...props
}: HeaderProps) => {
  const { image, title } = content;
  const { query } = useRouter();

  return (
    <div {...storyblokEditable(content as any)}>
      <Box
        pt={first ? [5, 8] : [2, 5]}
        pb={first ? 0 : [2, 5]}
        display="flex"
        flexDirection="column"
        {...props}
      >
        {!!image?.filename && (
          <Box
            bg="grey00"
            overflow="hidden"
            width="100%"
            maxWidth="maxWidth"
            mx="auto"
            css={{
              ".storyblok-image-wrapper": {
                height: "100%",
              },
            }}
            height={["414px", "400px"]}
            mb={[8, 0]}
            order={[1, 2]}
          >
            {!!image?.filename && (
              <Image
                width="100%"
                src={image.filename}
                alt={image.alt}
                fluid={1440}
              />
            )}
          </Box>
        )}
        <LegacyContainer mb={[2, 4]} order={[2, 1]}>
          {!!breadcrumb && (
            <Breadcrumb mb={0} items={getBreadcrumbItems(query)} />
          )}
          {!!title && (
            <Heading as={first ? "h1" : "h2"} variant="h2" color="grey900">
              {title}
            </Heading>
          )}
        </LegacyContainer>
      </Box>
    </div>
  );
};
