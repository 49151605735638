import { Box, Flex, css, Icon } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import { defineMessages, useIntl } from "react-intl";
import storyblokEditable from "~lib/storyblokEditable";

import {
  LegacyButton,
  Heading,
  Slider,
  Text,
  LegacyContainer,
} from "~components";
import { ChevronRight } from "~components/common/Icons";

import { TitleCombo } from "./Organisms/TitleCombo";

const messages = defineMessages({
  readMore: "Learn more",
});

export const Pages = ({ content }) => {
  const intl = useIntl();

  if (!content) return null;

  return (
    <div {...storyblokEditable(content)}>
      <Box bg="primary200">
        <LegacyContainer py={[4, 10]}>
          <TitleCombo color="primary" content={content} />

          <Box
            css={{
              ".swiper-wrapper": {
                alignItems: "stretch",
              },
              ".swiper-slide": {
                height: "auto",
              },
            }}
          >
            <Slider
              slideWidth={[
                "100% !important",
                "368px !important",
                "368px !important",
              ]}
            >
              {content?.pages?.map(({ full_slug, content: page }) => (
                <div {...storyblokEditable(page)} key={page?._uid}>
                  <LegacyButton
                    to={full_slug}
                    variant="unstyled"
                    css={css({
                      "& > div": {
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      },
                    })}
                  >
                    {!!page?.thumbnail_image && (
                      <Box
                        width="100%"
                        height="264px"
                        css={{
                          ".storyblok-image-wrapper": {
                            height: "100%",
                          },
                        }}
                      >
                        <Image
                          src={page.thumbnail_image.filename}
                          alt={page.thumbnail_image.alt}
                          fluid={[368, 264]}
                        />
                      </Box>
                    )}
                    <Flex
                      flexDirection="column"
                      p={[2, 3]}
                      bg="white"
                      height="100%"
                    >
                      <Heading color="grey900" variant={"h4"} as="h4" mb={2}>
                        {page?.title}
                      </Heading>
                      {!!page?.synopsis && (
                        <Text variant="medium" mb={[1.5, 2]}>
                          {page.synopsis}
                        </Text>
                      )}
                      <Box flex="1" />
                      <LegacyButton
                        variant="link-md"
                        display="inline-flex"
                        alignSelf="flex-start"
                        color="grey900"
                      >
                        {intl.formatMessage(messages.readMore)}
                        <Icon icon={ChevronRight} ml={0.5} mt={0.25} />
                      </LegacyButton>
                    </Flex>
                  </LegacyButton>
                </div>
              ))}
            </Slider>
          </Box>
        </LegacyContainer>
      </Box>
    </div>
  );
};
