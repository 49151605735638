import { Box, Stack, Text } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import storyblokEditable from "~lib/storyblokEditable";

import { Accordion } from "~components";

export const Contents = ({ content }) => {
  if (!content) return null;

  const { title, image, contents } = content;

  const linksSlice = Math.ceil(contents?.length / 2);
  const firstHalf = contents?.slice(0, linksSlice);
  const secondHalf = contents?.slice(linksSlice);

  return (
    <div {...storyblokEditable(content)}>
      <Accordion title={title}>
        {!!image && (
          <Box
            borderRadius="md"
            overflow="hidden"
            bg="secondary400"
            mb={[3, 4]}
            height={["240px", "560px"]}
            css={{
              ".storyblok-image-wrapper": {
                maxHeight: "560px",
              },
            }}
          >
            <Image src={image.filename} alt={image.alt} fluid={1200} />
          </Box>
        )}

        <Stack
          pb={2}
          space={[1, 10]}
          flexDirection={["column", "row"]}
          color="grey900"
        >
          <Stack
            flexDirection="column"
            space={1}
            maxWidth={["none", "calc(50% - 40px)"]}
          >
            {firstHalf?.map((item) => (
              <div {...storyblokEditable(item)} key={item?._uid}>
                <Text as={"li" as any} variant="medium">
                  {item?.text}
                </Text>
              </div>
            ))}
          </Stack>
          <Stack
            flexDirection="column"
            space={1}
            maxWidth={["none", "calc(50% - 40px)"]}
          >
            {secondHalf?.map((item) => (
              <div {...storyblokEditable(item)} key={item?._uid}>
                <Text as={"li" as any} variant="medium">
                  {item?.text}
                </Text>
              </div>
            ))}
          </Stack>
        </Stack>
      </Accordion>
    </div>
  );
};
