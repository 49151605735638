import { ReactNode } from "react";
import { Box, Flex, SystemProps } from "@storyofams/react-ui";

import { LegacyContainer, Heading } from "~components";

interface HeroProps extends SystemProps {
  textColor?: "dark" | "light";
  title: string | ReactNode;
}

export const Hero = ({ title, ...props }: HeroProps) => {
  return (
    <Box>
      {/* TODO: Put in another folder, this section is not one of the dynamic ones */}
      <Flex
        alignItems="center"
        width="100%"
        height={["200px", "320px"]}
        position="relative"
        overflow="hidden"
        {...props}
      >
        <LegacyContainer zIndex={1}>
          <Heading as="h1" variant="h2" color="grey900">
            {title}
          </Heading>
        </LegacyContainer>
      </Flex>
    </Box>
  );
};
