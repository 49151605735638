import { Box, Stack } from "@storyofams/react-ui";
import { defineMessages, FormattedMessage } from "react-intl";

import {
  LegacyContainer,
  Heading,
  Slider,
  ProductCard,
  LegacyButton,
} from "~components";

const messages = defineMessages({
  title: "More from this collection",
  button: "View collection",
});

export const MoreInCollection = ({ products, link }) => {
  return (
    <LegacyContainer>
      {/* TODO: Put in another folder, this section is not one of the dynamic ones */}
      <Stack
        py={[4, 10]}
        flexDirection="column"
        space={[2, 2, 5]}
        alignItems="center"
      >
        <Stack
          justifyContent="space-between"
          flexDirection={["column", "row"]}
          space={1}
          width="100%"
        >
          <Heading as="h3" variant="h3">
            <FormattedMessage {...messages.title} />
          </Heading>
          <LegacyButton to={link} variant="primary" display={["none", "flex"]}>
            <FormattedMessage {...messages.button} />
          </LegacyButton>
        </Stack>
        <Box width="100%">
          <Slider
            slideWidth={[
              "80% !important",
              "80% !important",
              "calc(33.33% - 16px) !important",
            ]}
          >
            {!!products &&
              products?.map(({ node }) => (
                <Box key={node?.id}>
                  <ProductCard {...node} />
                </Box>
              ))}
          </Slider>
        </Box>
        <Box textAlign="center" display={["block", "none"]}>
          <LegacyButton
            variant="primary"
            display="inline-flex"
            marginX="auto"
            mt={4}
            to={link}
          >
            <FormattedMessage {...messages.button} />
          </LegacyButton>
        </Box>
      </Stack>
    </LegacyContainer>
  );
};
