import { Box, SystemProps, css } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import storyblokEditable from "~lib/storyblokEditable";

import { LegacyContainer, Link } from "~components";
import ConditionalWrapper from "~lib/conditionalWrapper";
import { useGetHref } from "~lib";
import { useImageRatio } from "~lib/useImageRatio";

type FullWidthImageProps = {
  content: {
    image?: any;
    image_link?: any;
  };
  first: boolean;
} & SystemProps;

export const FullWidthImage = ({
  content,
  first,
  ...props
}: FullWidthImageProps) => {
  const { image, image_link } = content;
  const { getHref } = useGetHref();
  const { image: ref, imageRatio: aspectRatio } = useImageRatio();

  return (
    <div {...storyblokEditable(content as any)}>
      <LegacyContainer pt={first ? 0 : [2, 5]} pb={[2, 5]} {...props}>
        <Box
          overflow="hidden"
          width="100%"
          py={0}
          css={css({
            ".storyblok-image-wrapper": {
              height: "100%",
              aspectRatio: `${aspectRatio}`,
            },
          })}
          ref={(el) => {
            ref.current = el?.querySelector("img");
          }}
        >
          {!!image && (
            <ConditionalWrapper
              condition={!!image_link}
              wrapper={(children) => (
                <Link href={getHref(image_link)}>
                  <a>{children}</a>
                </Link>
              )}
            >
              <Image
                width="100%"
                src={image.filename}
                alt={image.alt}
                fluid={996}
              />
            </ConditionalWrapper>
          )}
        </Box>
      </LegacyContainer>
    </div>
  );
};
