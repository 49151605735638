import { SystemProps } from "@storyofams/react-ui";
import dynamic from "next/dynamic";
import React from "react";

import type { BlogPosts as BlogPostsType } from "../organisms/BlogPosts/BlogPosts";
import type { DynamicFaq as FAQType } from "./DynamicFaq";
import type { FullWidthImage as FullWidthImageType } from "./FullWidthImage";
import type { FullWidthVideo as FullWidthVideoType } from "./FullWidthVideo";
import type { Header as HeaderType } from "./Header";
import type { ImageCollection as OldImageCollectionType } from "./ImageCollectionOld";
import type { ImageGrid as OldImageGridType } from "./ImageGridOld";
import type {
  ImageCollection as ImageCollectionType,
  ImageGrid as ImageGridType,
  Paragraph as ParagraphType,
  TextWithImage as TextWithImageType,
  OurRooms as OurRoomsType,
  Intro as IntroType,
  SpecialEditions as OurSpecialEditionsType,
  HeaderFullscreen as HeaderFullscreenType,
} from "../organisms";
import type { OurCollections as OurCollectionsType } from "../organisms/FeaturedCollection/FeaturedCollection";
import type { OurProducts as OurProductsType } from "../organisms/OurProducts/OurProducts";
import type { Pages as PagesType } from "./Pages";
import type { Quote as QuoteType } from "../molecules/Quote/Quote";
import type { RichText as RichTextType } from "./RichText";
import type { ShowMore as ShowMoreType } from "./ShowMore";
import type { HubspotForm as HubspotFormType } from "../organisms/HubspotForm/HubspotForm";
import type { FullWidthBannerWithImage as FullWidthBannerWithImageType } from "../molecules/FullWidthBannerWithImage/FullWidthBannerWithImage";

const BlogPosts = dynamic(() =>
  import("../organisms/BlogPosts/BlogPosts").then((mod) => mod.BlogPosts)
) as typeof BlogPostsType;
const FAQ = dynamic(() =>
  import("./DynamicFaq").then((mod) => mod.DynamicFaq)
) as typeof FAQType;
const TextWithImage = dynamic(() =>
  import("../organisms/TextWithImage/TextWithImage").then((mod) => mod.TextWithImage)
) as typeof TextWithImageType;
const Header = dynamic(() =>
  import("./Header").then((mod) => mod.Header)
) as typeof HeaderType;
const HeaderFullscreen = dynamic(() =>
  import("../organisms/HeaderFullscreen/HeaderFullscreen").then((mod) => mod.HeaderFullscreen)
) as typeof HeaderFullscreenType;
const FullWidthBannerWithImage = dynamic(() =>
  import("../molecules/FullWidthBannerWithImage/FullWidthBannerWithImage").then(
    (mod) => mod.FullWidthBannerWithImage
  )
) as typeof FullWidthBannerWithImageType;
const FullWidthImage = dynamic(() =>
  import("./FullWidthImage").then((mod) => mod.FullWidthImage)
) as typeof FullWidthImageType;
const RichText = dynamic(() =>
  import("./RichText").then((mod) => mod.RichText)
) as typeof RichTextType;
const FullWidthVideo = dynamic(() =>
  import("./FullWidthVideo").then((mod) => mod.FullWidthVideo)
) as typeof FullWidthVideoType;
const ImageGridOld = dynamic(() =>
  import("./ImageGridOld").then((mod) => mod.ImageGrid)
) as typeof OldImageGridType;
const ImageCollectionOld = dynamic(() =>
  import("./ImageCollectionOld").then((mod) => mod.ImageCollection)
) as typeof OldImageCollectionType;
const ImageGrid = dynamic(() =>
  import("../organisms/ImageGrid/ImageGrid").then((mod) => mod.ImageGrid)
) as typeof ImageGridType;
const ImageCollection = dynamic(() =>
  import("../organisms/ImageCollection/ImageCollection").then(
    (mod) => mod.ImageCollection
  )
) as typeof ImageCollectionType;
const Intro = dynamic(() =>
  import("../organisms/Intro/Intro").then((mod) => mod.Intro)
) as typeof IntroType;
const Pages = dynamic(() =>
  import("./Pages").then((mod) => mod.Pages)
) as typeof PagesType;
const Paragraph = dynamic(() =>
  import("../organisms/Paragraph/Paragraph").then((mod) => mod.Paragraph)
) as typeof ParagraphType;
const Quote = dynamic(() =>
  import("../molecules/Quote/Quote").then((mod) => mod.Quote)
) as typeof QuoteType;
const ShowMore = dynamic(() =>
  import("./ShowMore").then((mod) => mod.ShowMore)
) as typeof ShowMoreType;
const OurProducts = dynamic(() =>
  import("../").then((mod) => mod.OurProducts)
) as typeof OurProductsType;
const OurCollections = dynamic(() =>
  import("../organisms/FeaturedCollection/FeaturedCollection").then((mod) => mod.OurCollections)
) as typeof OurCollectionsType;
const OurSpecialEditions = dynamic(() =>
  import("../organisms/SpecialEditions/SpecialEditions").then((mod) => mod.SpecialEditions)
) as typeof OurSpecialEditionsType;
const OurRooms = dynamic(() =>
  import("../organisms/OurRooms/OurRooms").then((mod) => mod.OurRooms)
) as typeof OurRoomsType;
const HubspotForm = dynamic(() =>
  import("../organisms/HubspotForm/HubspotForm").then((mod) => mod.HubspotForm)
) as typeof HubspotFormType;

const Components = {
  "blog-posts": BlogPosts,
  faq: FAQ,
  "text-with-image": TextWithImage,
  header: Header,
  "header-fullscreen": HeaderFullscreen,
  "full-width-image": FullWidthImage,
  "full-width-banner-with-image": FullWidthBannerWithImage,
  image: FullWidthImage,
  "rich-text": RichText,
  quote: Quote,
  pages: Pages,
  intro: Intro,
  paragraph: Paragraph,
  "image-grid": ImageGridOld,
  "image-collection": ImageCollectionOld,
  "image-grid-new": ImageGrid,
  "image-collection-new": ImageCollection,
  "full-width-video": FullWidthVideo,
  "show-more": ShowMore,
  "our-products": OurProducts,
  "our-collections": OurCollections,
  "our-special-editions": OurSpecialEditions,
  "our-rooms": OurRooms,
  "hubspot-form": HubspotForm,
};

type DynamicSectionsProps = {
  sections: Array<any>;
  skipFirst?: boolean;
} & SystemProps;

export const DynamicSections = ({
  sections,
  skipFirst,
  ...props
}: DynamicSectionsProps): any => {
  let twoFirstSections = false;

  if (sections[0] && sections[0]?.component === "header-fullscreen") {
    twoFirstSections = true;
    skipFirst = true;
  }

  if (!sections?.length) {
    return null;
  }

  return sections?.map((section, i) => {
    const Component = Components[section?.component];

    if (!section || typeof Component === "undefined") return null;

    return (
      <Component
        content={section}
        key={section?._uid}
        skipFirst={skipFirst}
        first={twoFirstSections ? i <= 1 : i === 0}
        {...props}
      />
    );
  });
};
