import { Flex, Box, SystemProps } from "@storyofams/react-ui";
import { LegacyButton, Heading, Text } from "~components";
import { useGetHref } from "~lib";

type TitleComboProps = {
  content: {
    title: string;
    description?: string;
    link_label?: string;
    link_url?: any;
  };
  color?: "primary" | "secondary";
  first?: boolean;
} & SystemProps;

export const TitleCombo = ({
  content,
  first,
  color,
  ...props
}: TitleComboProps) => {
  const { getHref } = useGetHref();
  if (!content?.title) {
    return null;
  }
  const linkUrl = getHref(content?.link_url);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="flex-end"
      mb={[2, 2, 4]}
      width="100%"
      {...props}
    >
      <Box>
        <Heading
          variant="h3"
          as={first ? "h1" : "h2"}
          color={color || "grey900"}
        >
          {content?.title}
        </Heading>
        {content?.description && (
          <Text variant="2xl" color={color || "black"} mt={1}>
            {content?.description}
          </Text>
        )}
      </Box>

      {content?.link_label && linkUrl && (
        <LegacyButton
          variant="primary"
          href={linkUrl}
          display={["none", "flex"]}
          whiteSpace="nowrap"
        >
          {content?.link_label}
        </LegacyButton>
      )}
    </Flex>
  );
};
