import { Box, Stack, SystemProps } from "@storyofams/react-ui";

import { LegacyContainer } from "~components";

import { Contents } from "./Contents";
import { Faq } from "./Faq";
import { Setup } from "./Setup";

type ProductAccordionProps = {
  contents?: any;
  faq?: any;
  setup?: any;
} & SystemProps;

export const ProductAccordion = ({
  contents,
  faq,
  setup,
  ...props
}: ProductAccordionProps) => {
  return (
    <Box bg="secondary200" {...props}>
      <LegacyContainer>
        <Stack
          py={[4, 10]}
          flexDirection="column"
          space={[3, 5]}
          alignItems="center"
        >
          <Stack
            borderTop="1px solid"
            borderTopColor="grey900"
            space={0}
            flexDirection="column"
            width="100%"
          >
            {!!contents && <Contents content={contents} />}
            {!!setup && <Setup content={setup} />}
            {!!faq && <Faq content={faq} />}
          </Stack>
        </Stack>
      </LegacyContainer>
    </Box>
  );
};
