import { Box, Grid } from "@storyofams/react-ui";
import { Image } from "@storyofams/storyblok-toolkit";
import { defineMessages, FormattedMessage } from "react-intl";
import storyblokEditable from "~lib/storyblokEditable";

import { Accordion, Heading, RichText, Text } from "~components";

const messages = defineMessages({
  step: "Step",
});

export const Setup = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div {...storyblokEditable(content)}>
      <Accordion title={content?.title}>
        <Grid columnGap={3} rowSize={[1, 2, 3]} rowGap={[3, 5]} pb={6}>
          {content?.steps?.map((step, idx) => (
            <div {...storyblokEditable(step)} key={step?._uid}>
              <Box>
                {!!step?.image && (
                  <Box
                    height={["240px", "320px"]}
                    borderRadius="md"
                    overflow="hidden"
                    bg="secondary400"
                    mb={[2, 3]}
                    css={{
                      ".storyblok-image-wrapper": {
                        height: "100%",
                      },
                    }}
                  >
                    <Image src={step.image?.filename} alt={step.image?.alt} />
                  </Box>
                )}

                <Text mb={0.25} color="grey900" fontWeight="bold">
                  <FormattedMessage {...messages.step} /> {idx + 1}
                </Text>
                <Heading mb={2} variant="h4" as="h6" color="grey900">
                  {step?.title}
                </Heading>
                {!!step?.text && <RichText text={step.text} color="grey900" />}
              </Box>
            </div>
          ))}
        </Grid>
      </Accordion>
    </div>
  );
};
